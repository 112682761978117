import M$plugin$Infocoordinates from '/home/alvaroramirez/Escritorio/API-CNIG/api-ign-js/src/plugins/infocoordinates/src/facade/js/infocoordinates';
import M$control$InfocoordinatesControl from '/home/alvaroramirez/Escritorio/API-CNIG/api-ign-js/src/plugins/infocoordinates/src/facade/js/infocoordinatescontrol';
import M$impl$control$InfocoordinatesControl from '/home/alvaroramirez/Escritorio/API-CNIG/api-ign-js/src/plugins/infocoordinates/src/impl/ol/js/infocoordinatescontrol';

if (!window.M.plugin) window.M.plugin = {};
if (!window.M.control) window.M.control = {};
if (!window.M.impl) window.M.impl = {};
if (!window.M.impl.control) window.M.impl.control = {};
window.M.plugin.Infocoordinates = M$plugin$Infocoordinates;
window.M.control.InfocoordinatesControl = M$control$InfocoordinatesControl;
window.M.impl.control.InfocoordinatesControl = M$impl$control$InfocoordinatesControl;
